import {FetchService} from "dc-services/fetch.service";

export function initChangeAddressModal() {
    const form = document.querySelector<HTMLFormElement>('#addEditAddressForm')
    if (form) {
        form.addEventListener('submit', (evt) => {
            const data = new FormData(form);
            const values = Object.fromEntries(data.entries() as Iterable<readonly [PropertyKey, File | string]>);

            FetchService.post('/em/changeCustomerAddress/update', values).then((res) => {
                console.log(res)
            })
        })
    }
}