import {loadReactScript} from "./loadScript";

const mergeMap = new Map();
const startPackagesInputsMap = new Map();
const regularDeliveryInputsMap = new Map();
const completePackagesInputsMap = new Map();

//CustomizationFieldIds, configuratorFields
mergeMap.set("IS-GROUP", "isGroup");
mergeMap.set("IS-COMPLETE", "isComplete");
mergeMap.set("IS-EXTENSION", "isExtension");

mergeMap.set("NAMES", "names");
mergeMap.set("PRINT-OPTION", "printOption");
mergeMap.set("BIRTHDAYS", "birthdays");
mergeMap.set("NO-OF", "countNames");

startPackagesInputsMap.set("S-SHIPPING-DATE", "startPackageDate");
startPackagesInputsMap.set("S-SHIP-TO-NAME", "startPackageName");
startPackagesInputsMap.set("S-SHIP-TO-ADRESS", "startPackageAddress1");
startPackagesInputsMap.set("S-SHIP-TO-ADRESS-2", "startPackageAddress2");
startPackagesInputsMap.set("S-SHIP-TO-POSTCODE", "startPackagePostcode");
startPackagesInputsMap.set("S-SHIP-TO-CITY", "startPackageLocation");
startPackagesInputsMap.set("S-SHIP-TO-COUNTRY", "startPackageCountry");

regularDeliveryInputsMap.set("L-SHIPPING-DATE", "countryLettersDate");
regularDeliveryInputsMap.set("L-SHIP-TO-NAME", "countryLettersName");
regularDeliveryInputsMap.set("L-SHIP-TO-ADRESS", "countryLettersAddress1");
regularDeliveryInputsMap.set("L-SHIP-TO-ADRESS-2", "countryLettersAddress2");
regularDeliveryInputsMap.set("L-SHIP-TO-POSTCODE", "countryLettersPostcode");
regularDeliveryInputsMap.set("L-SHIP-TO-CITY", "countryLettersLocation");
regularDeliveryInputsMap.set("L-SHIP-TO-COUNTRY", "countryLettersCountry");

//shipping date for group and complete
completePackagesInputsMap.set("C-SHIPPING-DATE", "completePackageDate");

// Merge startPackagesInputsMap into mergeMap
startPackagesInputsMap.forEach((value, key) => {
    mergeMap.set(key, value);
});

// Merge regularDeliveryInputsMap into mergeMap
regularDeliveryInputsMap.forEach((value, key) => {
    mergeMap.set(key, value);
});

// Merge completePackagesInputsMap into mergeMap
completePackagesInputsMap.forEach((value, key) => {
    mergeMap.set(key, value);
});

function manipulateData(customerData){
    customerData.printOption = "Singular";
    customerData.countNames = 0;
    let isGroup = 0;

    if(customerData.isGroup){
        if(parseInt(customerData.isGroup) === 1){
            isGroup = 1;
        }
    }

    if(isGroup){
        customerData.names = customerData.nameGroup;
        customerData.countNames = customerData.numberChildrenGroup;
    }else{
        if(customerData.name){
            if(typeof customerData.name === "string"){
                customerData.countNames = 1;
            }else{
                let count = 0;
                for(let name of customerData.name){
                    if(name !== "" && name !== null){
                        count++;
                    }
                }
                customerData.countNames = count;
            }
        }

        if(customerData.startPackageNameFirstname){
            let firstname = customerData.startPackageNameFirstname;
            let surname = (customerData.startPackageNameSurname !== null)? " "+customerData.startPackageNameSurname.trim() : "";
            customerData.startPackageName = firstname.trim() + surname;
        }

        if(customerData.countryLettersFirstname){
            let firstname = customerData.countryLettersFirstname;
            let surname = (customerData.countryLettersSurname !== null)? " "+customerData.countryLettersSurname.trim() : "";
            customerData.countryLettersName = firstname.trim() + surname;
        }
    }

    if(customerData.countNames > 1){
        customerData.printOption = "Plural";
    }
}

function mergeFields(customerData){

    for(let input of document.querySelectorAll('#customizationFields input[type="text"]')){
        input.value = "";
        let inputID = input.getAttribute("id");
        if(mergeMap.has(inputID)){
            let mappedFieldValue = mergeMap.get(inputID);
            if(customerData[mappedFieldValue]){
                input.value = customerData[mappedFieldValue];
                input.value = input.value.trim();
            }
        }else{
            console.log(inputID + " not found in map");
        }
    }

    for(let input of document.querySelectorAll('#customizationFields input[type="checkbox"]')){
        let checked = false;
        let inputID = input.getAttribute("id");
        let inputText = input.closest("label").innerText.trim();

        if(customerData.addons){
            if(typeof customerData.addons === "string"){
                if(inputID === customerData.addons || inputText === customerData.addons){
                    checked = true;
                }
            }else{
                for(let addon of customerData.addons){
                    if(inputID === addon || inputText === addon){
                        checked = true;
                    }
                }
            }
        }

        if(checked){
            input.checked = true;
            input.setAttribute('checked', 'checked');
        }else{
            input.checked = false;
            input.removeAttribute('checked');
        }
    }
}

function observeConfigurator(configurator){

    const config = { attributes: true, childList: false, subtree: false };

    const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
            if (mutation.type === "attributes") {
                if(mutation.attributeName === "data-customer-data"){
                    const jsonCustomerData = configurator.dataset.customerData;
                    const customerData = JSON.parse(jsonCustomerData);
                    manipulateData(customerData);
                    mergeFields(customerData);
                    const orderButton = document.querySelector("#form_queue .orderButton");
                    if(orderButton){
                        setTimeout(function (){
                            orderButton.click();
                        },500);
                    }
                }
            }
        }
    };

    const observer = new MutationObserver(callback);

    observer.observe(configurator, config);
}

function setPrices(configurator){
    let price = 0.00;
    let priceNotice = "";
    let priceAddon = "";
    const selectWrapper = document.getElementById("selectBoxWrapper");
    let itemPrice = document.querySelector(".subsc_per_month");
    let itemPriceNotice = document.querySelector(".priceNotice");
    let itemPriceAddon = document.querySelector(".subsc_one_time");
    let form = document.getElementById("itemcard_order_button_form_subsc");

    if(selectWrapper){
        const selectedRadiobox = selectWrapper.querySelector("input[type='radio']:checked");
        if(selectedRadiobox){
            const selectBox = selectedRadiobox.closest(".selectBox");
            if(selectBox){
                itemPrice = selectBox.querySelector(".subsc_per_month");
                itemPriceNotice = selectBox.querySelector(".taxNote");
                itemPriceAddon = selectBox.querySelector(".subsc_one_time");
            }
        }

        if(form.dataset.hasOwnProperty('action') == false) {
            itemPrice = document.querySelector(".itemPrice");
        }
    }else{
        console.error("cannot find select Wrapper");
    }

    if(itemPrice){
        price = itemPrice.innerText;
    }

    if(itemPriceAddon) {
        priceAddon = itemPriceAddon.innerText;
    }

    if(itemPriceNotice){
        priceNotice = itemPriceNotice.innerText;
    }

    configurator.dataset.priceAddon = priceAddon;
    configurator.dataset.price = price;
    configurator.dataset.priceNotice = priceNotice;
}

function setOptions(configurator){
    let options = {
        hasRegularDelivery: 0,
        isGroup: 0,
        isComplete: 0,
        isRenewal: 1
    };

    let startPackagesInputsMapsKeys = [ ...startPackagesInputsMap.keys() ];
    let regularDeliveryInputsMapsKeys = [ ...regularDeliveryInputsMap.keys() ];

    for(let input of document.querySelectorAll('#customizationFields input[type="text"]')){
        let inputID = input.getAttribute("id");

        if(inputID === "IS-GROUP"){
            options.isGroup = 1;
        }

        if(inputID === "IS-COMPLETE"){
            options.isComplete = 1;
        }

        if(startPackagesInputsMapsKeys.includes(inputID)){
            options.isRenewal = 0;
        }

        if(inputID === "IS-EXTENSION"){
            options.isExtension = 1;
        }
    }

    configurator.dataset.options = JSON.stringify(options);
}

function initConfigurator(){
    const configurator = document.getElementById("configurator");
    if(configurator){
        setPrices(configurator);
        setOptions(configurator);
        loadReactScript(configurator.dataset.script).then(() => {
            observeConfigurator(configurator);
        }).catch(() => {
            console.log('Error loading configurator app');
        });
    }
}


export function loadConfigurator(){
    let interval = false;
    const customizeButton = document.querySelector(".js-addToBasketItemcard.orderButton--customizable");

    if(customizeButton){
        customizeButton.addEventListener("click",function (){
            interval = setInterval(function (){
                const modalUserQueue = document.getElementById("modalUserQueue");
                if(modalUserQueue){
                    if(modalUserQueue.classList.contains("open")){
                        clearInterval(interval);
                        initConfigurator();
                    }
                }
            },300);
        });
    }
}